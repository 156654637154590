import { useState } from 'react';
import './SearchBar.css';

function SearchBar(props: any) {

    function handleChange(evt: any) {
        props.onSearch(evt.target.value)
    }

    return (
        <div className="search-bar">
            <input type="search" name="search" pattern=".*\S.*" required onChange={handleChange} ></input>
            <button className="search-btn" type="submit">
                <span>Search</span>
            </button>
        </div>
    );
}

export default SearchBar;