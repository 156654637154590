
import './CardsList.css';
import InfiniteScroll from 'react-infinite-scroller';   
import { Game } from '../../interfaces/GameInterface';
import { useEffect, useRef, useState } from 'react';

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    },[value]);
    return ref.current;
}

function CardsList(props: any) {
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);


    const filteredGames = props.games.filter((game: Game) => {
        return game.title.toLowerCase().match(new RegExp(props.searchTerm.toLowerCase(), 'g'));
    });

    const previousFilteredList: any = usePrevious(filteredGames);

    //reset page
    for (let i=0; i< currentPage; i++){
        if (filteredGames.length < i*10){
            setCurrentPage(i)
        }
    }

    //reset hasMore if the results change
    if (previousFilteredList !== undefined &&  previousFilteredList.length < filteredGames.length){
        if (hasMore === false)
        setHasMore(true)
    }

    const gamesToRender = filteredGames.slice(0, currentPage*10).map((game: Game) => {
        return (
            <div className="wrapper" key={game.image}>
                <div className="overviewInfo">
                    <div className="productinfo">
                        <div className="grouptext">
                            <h3>PUBLISHER</h3>
                            <p>{game.publisher} &nbsp;</p>
                        </div>
                        <div className="grouptext">
                            <h3>DEVELOPER</h3>
                            <p>{game.developer}&nbsp;</p>
                        </div>
                        <div className="grouptext">
                            <h3>GENRE</h3>
                            <p>{game.genre}</p>
                        </div>
                        <div className="grouptext">
                            <h3>RELEASE</h3>
                            <p>EU: {game.eu_released}&nbsp;</p>
                            <p>JP: {game.jp_released}&nbsp;</p>
                            <p>USA: {game.usa_released}&nbsp;</p>
                        </div>
                        
                    </div>
                    
                </div>
                <div className="productSpecifications">
                    <div className='title-content'> 
                        <span>{game.title}</span> 
                    </div>
                    <p> Released: {game.released} </p>
                    
                        <div className="productImage">
                            <img src={`/images/${game.image}`} alt="product: ps5 controller image" onError={(e: React.SyntheticEvent<HTMLImageElement, Event>)=>{
                                e.currentTarget.onerror = null
                                e.currentTarget.src = '/images/undefined.jpg'
                            }}></img>
                            {/* <img src={`http://vitagamesdb.com/images/${game.image}`}></img> */}
                        </div>
                    
                    {/* <div className="productFeatures">
                    <div className="feature">
                        <div className="featureIcon">
                        </div>
                        <div className="featureText">
                        <p> <strong>Futuristic</strong></p>
                        <p>Design</p>
                        </div>
                    </div>
                    <div className="feature">
                        <div className="featureIcon">
                        </div>
                        <div className="featureText">
                        <p> <strong>Built-in</strong></p>
                        <p>Microphone</p>
                        </div>
                    </div>
                    <div className="feature">
                        <div className="featureIcon">
                        </div>
                        <div className="featureText">
                        <p> <strong>Haptic</strong></p>
                        <p>Feedback</p>
                        </div>
                        </div>
                    <div className="feature">
                        <div className="featureIcon">
                        </div>
                        <div className="featureText">
                        <p> <strong>Fast charge</strong></p>
                        <p>USB-C port</p>
                        </div>
                    </div>
                    </div>
                    
                    <div className="checkoutButton">
                    <div className="priceTag">
                        <span>$</span>50
                    </div>
                    <button className="preorder">
                        <p>Preorder</p>
                        <div className="buttonaction">
                        <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    d="M23.0677 11.9929L18.818 7.75739L17.4061 9.17398L19.2415 11.0032L0.932469 11.0012L0.932251 13.0012L19.2369 13.0032L17.4155 14.8308L18.8321 16.2426L23.0677 11.9929Z"
                    fill="currentColor"
                />
                </svg>
                        </div>
                    </button> 
                    </div> */}
                </div>

            </div>
        );
    })

    function render() {
        if (props.games.length > 0){
            return (
                <InfiniteScroll
                    pageStart={currentPage}
                    loadMore={fetchMoreGames}
                    hasMore={hasMore}
                    loader={<div className="loader" key={0}></div>}
                >   
                    <div className='container'>
                        {gamesToRender}
                    </div>
                    
                </InfiniteScroll>
            );
        }
        else return <div>Loading ...</div>;
    }

    function fetchMoreGames(page: number) {
        setCurrentPage(page);

        if (filteredGames.length <= currentPage*10){
            setHasMore(false);
        }
        else {
            setHasMore(true);
        }
    }

    return (
        <div>
            {render()}
        </div>    
    );
}

export default CardsList;