import React, { useEffect, useState } from 'react';
import './App.css';
import CardsList from './components/CardsList/CardsList';
import SearchBar from './components/SearchBar/SearchBar';
import { Game } from './interfaces/GameInterface';

function App() {
  const [games, setGames] =  useState<Game[]>([])
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchGames();
  }, [])

  const fetchGames = async(): Promise<any> => {
    const response = await fetch('https://4389hu9h61.execute-api.us-east-1.amazonaws.com/production', {
      method: "GET",
    });
    const gamesJSON = await response.json();

    if (gamesJSON.errorMessage) {
      fetchGames()
    }
    if (gamesJSON.statusCode === 200){
      setGames(gamesJSON.body)
    }
  }

  function handleSearchChange(term: string) {
    setSearchTerm(term);
  }

  function renderSearchBar() {
    if (games.length > 0){
      return <SearchBar onSearch={handleSearchChange}></SearchBar>;
    }
  }

  return (
    <div className='app'>
      <header className='header'>Vita Games DB</header>
      {renderSearchBar()}
      <div className='container'>
        <CardsList searchTerm={searchTerm} games={games}></CardsList>
      </div>
    </div>
  );
}

export default App;


